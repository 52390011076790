import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

// profile routing
const Profile = Loadable(lazy(() => import('views/profile')));
const ProfileView = Loadable(lazy(() => import('views/profile/social-profile')));

// login option 3 routing
const DVLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/DVLogin')));
const DVRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/DVRegister')));
const DVCheckMail = Loadable(lazy(() => import('views/pages/authentication/authentication3/DVCheckMail')));
const DVVerifyEmail = Loadable(lazy(() => import('views/pages/authentication/authentication3/DVVerifyEmail')));
const DVCreatePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/DVCreatePassword')));
const DVForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/DVForgotPassword')));
const DVResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/DVResetPassword')));
const AuthResetPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword3')));
const AuthCodeVerification3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/CodeVerification3')));

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const MaintenanceComingSoon1 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon1')));
const MaintenanceComingSoon2 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon2')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));

// landing & contact-us routing
const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const PagesContactUS = Loadable(lazy(() => import('views/pages/contact-us')));

// shared team detail
const SharedTeamDetail = Loadable(lazy(() => import('views/profile/profile-teams/shared/SharedTeamDetail')));
const SharedProfileDetail = Loadable(lazy(() => import('views/profile/profile-teams/shared/SharedProfileDetail')));

// ===========================|| AUTHENTICATION ROUTING ||=========================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <DVLogin />
        },
        {
            path: '/register',
            element: <DVRegister />
        },
        {
            path: '/check-mail',
            element: <DVCheckMail />
        },
        {
            path: '/api/v1/specialist/confirmation/:token',
            element: <DVVerifyEmail type="register" />
        },
        {
            path: '/api/v1/specialists/password_reset/:token',
            element: <DVVerifyEmail type="forgot" />
        },
        {
            path: '/create-password/:id',
            element: <DVCreatePassword />
        },
        {
            path: '/forgot-password',
            element: <DVForgotPassword />
        },
        {
            path: '/reset-password/:id',
            element: <DVResetPassword />
        },
        {
            path: '/code-verification',
            element: <AuthCodeVerification3 />
        },
        {
            path: '/pages/error',
            element: <MaintenanceError />
        },
        {
            path: '/pages/coming-soon1',
            element: <MaintenanceComingSoon1 />
        },
        {
            path: '/pages/coming-soon2',
            element: <MaintenanceComingSoon2 />
        },
        {
            path: '/pages/under-construction',
            element: <MaintenanceUnderConstruction />
        },
        {
            path: '/pages/landing',
            element: <PagesLanding />
        },
        {
            path: '/pages/contact-us',
            element: <PagesContactUS />
        },
        {
            path: '/profile-setup',
            element: <Profile />
        },
        {
            path: '/profile-view',
            element: <ProfileView />
        },
        {
            path: '/shared-team/:teamId/:isCustom/:projectId',
            element: <SharedTeamDetail />
        },
        {
            path: '/shared-team/:teamId/:isCustom/:projectId/:profileId',
            element: <SharedProfileDetail />
        }
    ]
};

export default AuthenticationRoutes;
