import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconAffiliate, IconUsers, IconFileAnalytics } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconAffiliate, IconUsers, IconFileAnalytics };

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/profile/profile-dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
        {
            id: 'network',
            title: <FormattedMessage id="Network" />,
            type: 'item',
            url: '/profile/profile-network/network',
            icon: icons.IconAffiliate,
            breadcrumbs: true
        },
        {
            id: 'myteams',
            title: <FormattedMessage id="My Teams" />,
            type: 'item',
            url: '/profile/profile-teams/teams',
            icon: icons.IconUsers,
            breadcrumbs: true
        },
        {
            id: 'myprojects',
            title: <FormattedMessage id="My Projects" />,
            type: 'item',
            url: '/profile/profile-projects/projects',
            icon: icons.IconFileAnalytics,
            breadcrumbs: true
            // children: [
            //     {
            //         id: 'file_digitalvillage',
            //         title: <FormattedMessage id="Digital village" />,
            //         type: 'item',
            //         url: 'user-profile'
            //     },
            //     {
            //         id: 'file_cred',
            //         title: <FormattedMessage id="Cred" />,
            //         type: 'item',
            //         url: 'user-profile'
            //     },
            //     {
            //         id: 'file_freshops',
            //         title: <FormattedMessage id="Freshops" />,
            //         type: 'item',
            //         url: 'user-profile'
            //     }
            // ]
        }
    ]
};

export default dashboard;
